<template>
  <div>
    <navbar></navbar>
    <div class="main">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/partner-profile/Navbar.vue';

export default {
  components: {
    Navbar
  }
}
</script>
