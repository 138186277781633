<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="navbar-left">
      <a class="navbar-brand">Юридическая проверка Реалист</a>
    </div>
    <button class="navbar-toggler"
            type="button">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div v-bind:class="navbarClass">
      <div class="mr-auto d-flex align-items-center">
        <ul class="navbar-nav">
          <li v-for="el in navItems"
              v-bind:key="el.name">
            <a v-on:click="routingTo(el.name)"
               v-bind:class="navLinkClass(el.name)"
               v-on:click.middle="openInNewTab(el.name)">
              {{ el.text }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';

const NAV_ITEMS = [
  {
    name: 'PartnerProfileList',
    text: 'Список'
  },
  {
    name: 'PartnerProfileCreateCalcClaim',
    text: 'Создание заявки'
  }
]

export default {
  data() {
    return {
      navbarIsOpened: false,
      navItems: NAV_ITEMS
    };
  },
  computed: {
    navbarClass() {
      return [
        'collapse navbar-collapse',
        {
          'show': this.navbarIsOpened
        }
      ]
    },
    ...mapGetters([
      'getPolygonId',
      'getGroupId',
      'isAdminRole',
      'canUserViewCalcClaims',
      'getCurrentRole',
      'getBrokerAssignInfo'
    ])
  },
  methods: {
    routingTo(name) {
      this.$router.push({
        name
      });
    },
    openInNewTab(name) {
      let routeData = this.$router.resolve({ name });
      window.open(routeData.href, '_blank');
    },
    navLinkClass(name) {
      return [
        'nav-link',
        {
          'active': this.$route.name === name
        }
      ]
    },
  }
};
</script>

<style lang="scss" scoped>
.navbar {
  box-shadow: 0 0 .8rem rgba(#000, .15);
  font-size: 1.4rem;
  height: 5.1rem;
  position: fixed;
  z-index: 2;
  left: 0;
  width: 100%;

  a {cursor: pointer;}
}
.nav-link {
  border: 2px solid transparent;
  padding: $navbar-padding-y;
  padding-top: 1.3rem;
  padding-bottom: 1.3rem;

  &.router-link-exact-active,
  &.active,
  &:hover {
    border-bottom-color: $primary;
  }
}

.navbar-brand {
  font-weight: 700;
}

.navbar-text {
  display: inline-flex;
  align-items: center;
}

.navbar-left {
  padding-right: .5rem;
}

.navbar-uid {
  font-size: 1rem;
  margin-top: -6px;
}

@media only screen and (max-width: 1320px) {
  .navbar {
    font-size: 1.3rem;
  }
}

@include media-breakpoint-down(md) {
  .navbar {
    position: relative;
    height: auto;
  }

  .navbar-brand {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .navbar-collapse {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .nav-item {
    margin-bottom: 1rem;
  }

  .nav-link {
    display: inline-block;
    vertical-align: top;
    padding-bottom: 0.5rem;
    padding-top: 0;
  }

  .navbar-text {
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>
